import React from "react";

const Checkbox = ({
  active,
  orientation = "row",
  leftComponent,
  rightComponent,
  size = 6,
  padding = 1.25,
  marginTop = 1,
  gap = 2,
  onClick,
  className = "",
  disabled = false,
}) => {
  return (
    <div
      className={`${className} flex flex-${orientation} items-center gap-${gap}`}
    >
      {leftComponent}
      <div
        tabIndex="0"
        onKeyPress={(e) => e.key === "Enter" && !disabled && onClick(e)}
        onClick={(e) => !disabled && onClick(e)}
        className={`flex-shrink-0 flex-grow-0 transition cursor-${
          disabled ? "default" : "pointer"
        } group rounded border-2 border-gray-300 focus:border-primary focus:outline-none mt-${marginTop} w-${size} h-${size} p-${padding} hover:border-gray-400`}
      >
        <div
          className={`h-full rounded-sm ${
            active ? "bg-primary" : "bg-transparent"
          }`}
        />
      </div>
      {rightComponent}
    </div>
  );
};

export const CheckboxWithLabel = ({
  active,
  orientation,
  leftLabel,
  rightLabel,
  size,
  padding,
  gap,
  onClick,
  className,
}) => {
  const leftComponent = leftLabel && <p className="text-10px">{leftLabel}</p>;
  const rightComponent = rightLabel && <p className="text-sm">{rightLabel}</p>;

  return (
    <Checkbox
      orientation={orientation}
      leftComponent={leftComponent}
      rightComponent={rightComponent}
      size={size}
      padding={padding}
      gap={gap}
      onClick={onClick}
      active={active}
      className={className}
    />
  );
};

export default Checkbox;
